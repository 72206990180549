import {useNavigate} from "react-router-dom";

export const useNavigateDetails = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/dashboard/groups/${hid}`)
}

export const useNavigateEdit = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/dashboard/groups/${hid}/edit`)
}

export type GroupTab = "prayer" | "settings" | "subscribers" | "about"

export const useNavigateDetailsTab = (hid: string) => {
    const navigate = useNavigate()
    return (tab: GroupTab) => navigate(`/dashboard/groups/${hid}/${tab}`)
}
export const useNavigationCreateGroup = () => {
    const navigate = useNavigate()
    return () => navigate("/dashboard/groups/add")
}

export const useNavigationInvitationList = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/dashboard/groups/${hid}/invitations`)
}

export const useNavigationInvitation = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/dashboard/groups/${hid}/invitations/add`)
}

export const useNavigationBack = () => {
    const navigate = useNavigate()
    return (folder: string) => navigate(`/dashboard/groups/${folder === 'own' ? 'mine' : 'shared'}`)
}