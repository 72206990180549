import {GroupOrigin} from "@profusion.media/profusion-core-group";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {Avatar, Card, CardHeader, IconButton, Paper, Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {useNavigationCreateGroup} from "../../hooks/navigation";
import {faUsers, faUsersRectangle} from "@fortawesome/pro-solid-svg-icons";

interface GroupListTitleProps {
    what: GroupOrigin
}

export const GroupListTitle = (props: GroupListTitleProps = {what: "all"}) => {
    const {t} = useTranslation()
    const navigateCreate = useNavigationCreateGroup()

    const isMine = props.what === "mine"

    const handleAdd = () => {
        navigateCreate()
    }

    const title =
        props.what === "mine" ? t("prayer.groups.mine.title", "Mes groupes") :
            props.what === "shared" ? t("prayer.groups.shared.title", "Groupes partagés avec moi") :
                t("prayer.groups.all.title", "Tous les groupes")

    const icon =
        props.what === "mine" ? faUsers : faUsersRectangle

    return <Card sx={{boxShadow: 1}}>
        <CardHeader title={title}
                    action={isMine &&
                        <IconButton
                            color="primary"
                            onClick={handleAdd}><FontAwesomeIcon icon={faPlusCircle}/></IconButton>
                    }
                    avatar={<Stack direction="row" spacing={1} alignItems="center">
                        <Avatar>
                            <FontAwesomeIcon icon={icon}/>
                        </Avatar>
                    </Stack>}
                    titleTypographyProps={{variant: "h5"}}
        />
    </Card>
}