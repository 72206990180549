import React from 'react';
import { Group } from '@profusion.media/profusion-core-group';
import { SelectInput } from '@profusion.media/profusion-ui';

type GroupSelectInputProps = {
    value: string
    groups: Group[]
    onSelect: (selectedGroupId: string) => void;
};

export const GroupSelectInput: React.FC<GroupSelectInputProps> = ({ value, groups, onSelect }) => {
    return (
        <SelectInput
            value={value}
            renderItem={(group: Group) => group.title}
            keyExtractor={(group: Group) => group.hid}
            values={groups}
            onChange={(hid) => onSelect(hid)}
        />
    );
};

