import {Await, Outlet, useRouteLoaderData} from "react-router-dom";
import {ActiveSubscriber, Group} from "@profusion.media/profusion-core-group";
import List from "@mui/material/List";
import {Card, Chip, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import {LoadDetailsReturn} from "../../loaders";
import {Suspense, useEffect, useState} from "react";
import {SubscriberAvatar} from "./SubscriberAvatar";
import {useNavigationInvitation} from "../../../hooks/navigation";
import {useTranslation} from "react-i18next";
import {InvitationsInfoBox} from "./InvitationsInfoBox";

export const GroupSubscriberList = () => {
    const {
        group: groupData,
        subscribers: subscribersData
    } = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const [group, setGroup] = useState<Group>()

    useEffect(() => {
        if (groupData) {
            groupData.then(response => setGroup(response.data))
        }
    }, [groupData]);

    return <Suspense fallback={<Typography>Loading subscribers...</Typography>}>
        {group && <InvitationsInfoBox group={group}/>}

        <Card>
            <Await resolve={subscribersData}>
                {({data: subscribers}) => <List>
                    {subscribers.map((subscriber: ActiveSubscriber) => <ListItem key={subscriber.hid}>
                        <ListItemIcon>
                            <SubscriberAvatar subscriber={subscriber}/>
                        </ListItemIcon>
                         <ListItemText primary={subscriber.user.displayName}
                                      secondary={subscriber.isHost && <Chip label={subscriber.isHost
                                          ? "Animateur" : ""}/>}
                        />
                    </ListItem>)}
                </List>}
            </Await>
        </Card>
        <Outlet/>
    </Suspense>

}