import {GroupList} from "./GroupList/GroupList";
import {GroupCreate} from "./GroupCreate/GroupCreate";
import {GroupDetails} from "./GroupDetails/GroupDetails";
import {SubscriberInvitation} from "./GroupDetails/components/SubscriberInvitation/SubscriberInvitation";
import {GroupInvitationAccept} from "./GroupInvitation/GroupInvitationAccept";
import {GroupCreateConfirm} from "./GroupCreate/GroupCreateConfirm";
import {loadList} from "./GroupList/loaders";
import {
    DetailsLoaderProps,
    loadAllOverviews,
    loadDetails,
    loadGroup,
    loadSubscriberSettings,
    SubscriberSettingsLoaderProps
} from "./GroupDetails/loaders";
import {Outlet, redirect, RouteObject} from "react-router-dom"
import {GroupSubscriberList} from "./GroupDetails/components/SubscriberList/GroupSubscriberList";
import {GroupSubscriberSettings} from "./GroupDetails/components/SubscriberSettings/GroupSubscriberSettings";
import {actionCreate, actionUpdate} from "./components/settings";
import {GroupSettingsUpdate} from "./GroupDetails/components/GroupSettings/GroupSettingsUpdate";
import {prayerGroupRouter} from "../Prayers/prayerRouter";
import {GroupAbout} from "./GroupDetails/components/GroupAbout/GroupAbout";
import {GroupInvitationList} from "./GroupDetails/components/SubscriberList/GroupInvitationList";


export const groupRouter: RouteObject[] = [
    {
        path: "groups",
        element: <Outlet/>,
        id: "groupsRoot",
        loader: (props) => loadAllOverviews(props),
        children: [
            {
                index: true,
                path: "mine",
                element: <GroupList/>,
                id: "groupMine",
                loader: () => loadList("mine"),
                shouldRevalidate: ({currentUrl}) => {
                    return currentUrl.pathname === "/dashboard/groups/add";
                },
                handle:{
                    "list": "mine"
                }
            },
            {
                path: "shared",
                element: <GroupList/>,
                id: "groupShared",
                loader: () => loadList("shared"),
                handle:{
                    "list": "shared"
                }
            },
            {
                path: "add",
                element: <GroupCreate/>,
                id: "groupCreate",
                action: (props) => actionCreate(props)
            },
            {
                path: ":hid",
                element: <GroupDetails/>,
                id: "groupDetails",
                loader: (props: DetailsLoaderProps) => loadDetails(props),
                children: [
                    {
                        path: "prayer-request",
                        loader: ({params}) => redirect(`/dashboard/groups/${params.hid}`)
                    },
                    {
                        path: "subscribers",
                        element: <GroupSubscriberList/>,
                        handle: {
                            tab: "2"
                        },
                    },
                    {
                        path: "invitations",
                        element: <GroupInvitationList/>,
                        id: "groupInvitationList",
                        handle: {
                            tab: "3"
                        },
                        children: [
                            {
                                path: "add",
                                element: <SubscriberInvitation/>,
                                id: "groupInvitation"
                            },
                        ]
                    },
                    {
                        path: "settings",
                        element: <GroupSubscriberSettings/>,
                        loader: (props: SubscriberSettingsLoaderProps) => loadSubscriberSettings(props),
                        handle: {
                            tab: "3"
                        }
                    },
                    {
                        path: "about",
                        element: <GroupAbout/>,
                        handle: {
                            tab: "4"
                        }
                    },

                    ...prayerGroupRouter
                ]
            },
            {
                path: ":hid/confirm",
                element: <GroupCreateConfirm/>,
                id: "groupCreateConfirm"
            },
            {
                path: ":hid/edit",
                element: <GroupSettingsUpdate/>,
                id: "groupSettingsUpdate",
                loader: (props: DetailsLoaderProps) => loadGroup(props),
                action: (props) => actionUpdate(props)
            },
        ]
    }
]

