import {ReactNode, useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Outlet, useMatches, UIMatch } from "react-router-dom";
import {useNavigateDetails, useNavigateDetailsTab} from "../../hooks/navigation";
import {Group} from "@profusion.media/profusion-core-group";
import {TabContext, TabList} from "@mui/lab";
import {useTabIndex} from "../hooks";

export interface GroupTabsProps {
    group: Group
}

export const GroupTabs = (props: GroupTabsProps) => {
    const {t} = useTranslation()
    const navigate = useNavigateDetailsTab(props.group.hid)
    const tabIndex = useTabIndex() || "1";

    return <Box sx={{width: '100%', typography: 'body1'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabContext value={tabIndex} >
            <TabList variant="scrollable"
                  scrollButtons
                  aria-label={t("group.details.tabs.aria", "Fonctionnalités du groupe") as string}>
                <Tab label={t("prayer.group.tabs.prayer", "Prière")}
                     value="1"
                     onClick={_ => navigate("prayer")}
                />
                <Tab label={t("prayer.group.tabs.subscribers", "Partenaires")}
                     value="2"
                     sx={{display: "hidden"}}
                     onClick={_ => navigate("subscribers")}
                />
                <Tab label={t("prayer.group.tabs.settings", "Paramètres")}
                     value="3"
                     onClick={_ => navigate("settings")}
                />
                <Tab label={t("prayer.group.tabs.about", "À propos")}
                     value="4"
                     onClick={_ => navigate("about")}
                />
            </TabList>
            </TabContext>
        </Box>
    </Box>
}
