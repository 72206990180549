import {PrayerEvent} from "@profusion.media/profusion-core-prayer"
import {PrayerCommentListItem} from "./PrayerCommentListItem";
import {Stack} from "@mui/material";

export interface PrayerCommentListProps {
    comments: PrayerEvent[]
}

export const PrayerCommentList = (props: PrayerCommentListProps) => {
    return <Stack spacing={2}>
        {props.comments.map(c => <PrayerCommentListItem key={c.hid} comment={c}/>)}
    </Stack>
}