import {Await, useLoaderData, useNavigate} from "react-router-dom";
import {loadHomeDataReturn} from "../Groups/GroupList/loaders";
import {Suspense} from "react";
import {HomeScreenFavorites} from "./components/HomeScreenFavorites";
import {HomeScreenGroups} from "./components/HomeScreenGroups";
import {Stack} from "@mui/material";
import {HomeEmptyGroupsMine} from "./components/HomeEmptyGroupsMine";

export const HomeScreen = () => {
    const {shared, mine, favorites} = useLoaderData() as loadHomeDataReturn

    const navigate = useNavigate()

    return <Suspense>
        <Stack spacing={4}>
            <Await resolve={favorites}>
                {({data: requests}) => requests.length ? <HomeScreenFavorites
                    onMore={() => navigate("/dashboard/favorites")}
                    requests={requests}/>: <></>}
            </Await>
            <Await resolve={shared}>
                {({data: groups}) => groups.length ? <HomeScreenGroups
                    onMore={() => navigate("/dashboard/groups/shared")}
                    groups={groups} title={"Partagé avec moi"}/> : <></>}
            </Await>
            <Await resolve={mine}>
                {({data: groups}) => groups.length ? <HomeScreenGroups
                    onMore={() => navigate("/dashboard/groups/mine")}
                    groups={groups} title={"Mes groupes"}/> : <HomeEmptyGroupsMine />}
            </Await>
        </Stack>
    </Suspense>
}