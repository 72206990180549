import {Group} from "@profusion.media/profusion-core-group";
import {Grid, Paper} from "@mui/material";
import {EmptyListIcon} from "../components/icons/PrayerIcons";
import Typography from "@mui/material/Typography";

export interface PrayerRequestListEmptyProps {
    group: Group
}

export const PrayerRequestListEmpty = (props: PrayerRequestListEmptyProps) => {
    const {group} = props

    return group.folder === 'own'
        ? <PrayerRequestListEmptyMine group={group}/>
        : <PrayerRequestListEmptyShared group={group}/>
}

export const PrayerRequestListEmptyMine = (props: PrayerRequestListEmptyProps) => {
    const {group} = props

    return <Paper sx={{p: 4}}>
        <Grid container maxWidth="sm">
            <Grid item xs={12} sm={3} textAlign="center">
                <EmptyListIcon folder="mine"
                               size="5x"
                               style={{
                                   "--fa-primary-color": "#6e290b",
                                   "--fa-secondary-color": "#c51187"
                               }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Vous n'avez pas encore posté de requête</Typography>
                <Typography variant="body2">Partagez-en une dès maintenant avec vos partenaires de prière</Typography>
            </Grid>
        </Grid>
    </Paper>
}

export const PrayerRequestListEmptyShared = (props: PrayerRequestListEmptyProps) => {
    const {group} = props

    return <Paper sx={{p: 4}}>
        <Grid container maxWidth="sm">
            <Grid item xs={12} sm={3} textAlign="center">
                <EmptyListIcon folder="shared"
                               size="5x"
                               style={{
                                   "--fa-primary-color": "#7bb21d",
                                   "--fa-secondary-color": "#8f330d"

                               }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Il n'y a pas encore de requête</Typography>
                <Typography variant="body2">Votre partenaire de prière va en partager une prochainement, restez à
                    l'écoute
                    !</Typography>
            </Grid>
        </Grid>
    </Paper>
}