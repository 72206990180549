import {defer, LoaderFunctionArgs} from "react-router-dom"
import profusion from "../../../../config/profusion";
import {
    loadPrayerRequestOverview as _loaderOverview,
    PrayerRequest,
    PrayerRequestOverview
} from "@profusion.media/profusion-core-prayer"

export type RequestOverviewLoaderProps = LoaderFunctionArgs<["hid", "rhid"]>


export type LoadPrayerRequestOverviewReturn = {
    overview: ReturnType<typeof _loaderOverview>,
    groupHid: string
    requestHid: string
}

export const loadPrayerRequestOverview = (loaderProps: RequestOverviewLoaderProps) => {
    const requestHid = loaderProps.params.rhid ?? ""
    const groupHid = loaderProps.params.hid ?? ""
    return defer({
        overview: _loaderOverview(profusion, requestHid),
        groupHid,
        requestHid
    })
}

export const usePrayerRequestOverview = (request: PrayerRequest): Promise<PrayerRequestOverview> => {
    return _loaderOverview(profusion, request.hid).then(response => response.data)
}