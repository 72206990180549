import {Group} from "@profusion.media/profusion-core-group";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigationBack} from "../../hooks/navigation";

export interface GroupBackButtonProps {
    group: Group
}

export const GroupBackButton = (props: GroupBackButtonProps) => {
    const {group} = props
    const navigate = useNavigationBack()
    return <FontAwesomeIcon onClick={_ => navigate(group.folder)}
        icon={faChevronLeft} />
}