import {Group} from "@profusion.media/profusion-core-group";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {useActionData, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {useState} from "react";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {GroupSettingsForm, GroupSettingsHelp} from "../components/settings";
import {ValidationErrors} from "@profusion.media/profusion-core";

export const GroupCreate = () => {
    const connectedUser = useConnectedUser()
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const [group, setGroup] = useState<any>({
        title: "",
        language: connectedUser?.language ?? "",
        feature: "prayer",
        welcome: "",
    })
    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'


    const handleSubmit = (data: any) => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return <FullScreenDialog title="Nouveau groupe"
                             showHelp
                             helpContent={<GroupSettingsHelp/>}
                             onClose={handleCancel}>

        <GroupSettingsForm group={group}
                           mode="create"
                           onSubmit={handleSubmit}
                           onCancel={handleCancel}
                           loading={loading}
                           validationErrors={validationErrors}/>
    </FullScreenDialog>
}