import {Group} from "@profusion.media/profusion-core-group";
import List from "@mui/material/List";
import {Divider, ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faPencil} from "@fortawesome/pro-solid-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import {DropDownMenu} from "@profusion.media/profusion-ui";
import {useNavigateEdit} from "../../hooks/navigation";

export interface GroupHeaderActionsProps {
    group: Group
}

const GroupActionsMine = (props: GroupHeaderActionsProps) => {
    const {group} = props
    const navigateEdit = useNavigateEdit()
    return <List>
        <ListItem onClick={_ => navigateEdit(group.hid)}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faPencil}/>
            </ListItemIcon>
            <ListItemText primary="Modifier"/>
        </ListItem>
        <Divider/>
        <ListItem>
            <ListItemIcon>
                <FontAwesomeIcon icon={faCircleMinus}/>
            </ListItemIcon>
            <ListItemText primary="Supprimer"/>
        </ListItem>
    </List>
}

const GroupActionsShared = (props: GroupHeaderActionsProps) => {
    const {group} = props

    return <List>
        <ListItem>
            <ListItemIcon>
                <FontAwesomeIcon icon={faCircleMinus}/>
            </ListItemIcon>
            <ListItemText primary="Quitter le groupe"/>
        </ListItem>
    </List>
}


export const GroupHeaderActions = (props: GroupHeaderActionsProps) => {
    const {group} = props


    return <DropDownMenu
        showClose={false}
        variant="compact"
        backgroundColor="#fff"
    >
        {group.folder === 'own' ? <GroupActionsMine group={group}/> : <GroupActionsShared group={group}/>}
    </DropDownMenu>
}
