import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom"
import ErrorPage from "../errors/ErrorPage";
import {Receiver} from "../Receiver";
import {GroupInvitationDetails} from "./Groups/GroupInvitation/GroupInvitationDetails"
import {DashboardApp} from "./DashboardApp";
import {groupRouter} from "./Groups/groupRouter";
import {prayerGlobalRouter} from "./Prayers/prayerRouter";
import {GroupInvitationAccept} from "./Groups/GroupInvitation/GroupInvitationAccept";
import {DetailsLoaderProps, loadDetails, loadGroup} from "./Groups/GroupDetails/loaders";
import {HomeScreen} from "./HomeScreen/HomeScreen";
import {loadHomeData} from "./Groups/GroupList/loaders";


const DashboardRouter = () => {

    const router = createBrowserRouter([
        {
            path: "/",
            errorElement: <ErrorPage/>,
            loader: () => redirect('/dashboard'),
        },
        {
            path: "/receiver",
            element: <Receiver/>
        },
        {
            path: "dashboard/groups/:hid/invitation/:ihid",
            element: <GroupInvitationDetails/>
        },
        {
            path: "dashboard/groups/:hid/invitation/:ihid/accept",
            element: <GroupInvitationAccept/>,
            id: "groupInvitationAccept",
            loader: (props: DetailsLoaderProps) => loadGroup(props),
        },
        {
            path: "/dashboard",
            id: "dashboard",
            element: <DashboardApp/>,
            children: [
                {
                    path: "",
                    element: <HomeScreen />,
                    loader: () => loadHomeData()
                },
                ...groupRouter,
                ...prayerGlobalRouter
            ]
        },
    ], {
        basename: "/prayer"
    })

    return <RouterProvider router={router}/>
}

export default DashboardRouter