import {useNavigate, useParams, useRevalidator} from "react-router-dom";
import {SubscriberInput, useGroupDetails, useSubscriberInvitationMutation} from "@profusion.media/profusion-core-group";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    CancelButton,
    Form,
    FormGroup,
    FormGroupErrorBox,
    FullScreenDialog,
    PhoneInput,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui";
import {Card, CardActions, CardContent, Stack} from "@mui/material";
import {useClientCountry} from "@profusion.media/profusion-core";


export const SubscriberInvitation = () => {
    const {hid} = useParams<{ hid: string }>()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [update, {loading, validationErrors}] = useSubscriberInvitationMutation()
    const {data: group, loading: loadingGroup} = useGroupDetails(hid ?? "")
    const [invitation, setInvitation] = useState<SubscriberInput>({
        firstName: "",
        lastName: "",
        language: group?.language ?? "fr-FR",
        message: "",
        email: "",
        phoneNumber: "",
        group: hid ?? "",
        redirectUrl: window.location.origin+"/prayer/dashboard/groups/"+hid,
    })
    const {country: defaultCountry} = useClientCountry()
    const revalidator = useRevalidator()


    if (loadingGroup) {
        return <>Loading</>
    }

    const handleSubmit = () => {
        update({input: invitation}).then(response => {
            if (!response.validationErrors) {
                revalidator.revalidate()
                navigate(-1)
            }
        })
    }

    const handleClose = () => {
        navigate("..", {replace: true})
    }

    const handleChangeValue = (name: string, value: string) => {
        setInvitation(prev => ({...prev, [name]: value}))
    }

    return <FullScreenDialog
        title={t("invitation.form.title", "Inviter un ami")}
        onClose={handleClose}
    >
        <Form onSubmit={handleSubmit}
              onCancel={handleClose}
              updating={loading}
              errors={validationErrors}>
            <Card>
                <CardContent>
                    <Stack spacing={4}>
                        <TextInput name="firstName" label={t("invitation.first_name", "Prénom")}
                                   value={invitation.firstName}
                                   onValueChange={handleChangeValue}/>
                        <TextInput name="lastName" label={t("invitation.last_name", "Nom")}
                                   value={invitation.lastName}
                                   onValueChange={handleChangeValue}/>
                        <FormGroup name="contactInfo">

                            <Stack spacing={4}>
                                <FormGroupErrorBox text="Remplissez au moins un des deux champs suivants"
                                                   errorCode="RequiresOneOfFields"/>
                                <TextInput name="email" label={t("invitation.email", "Courriel")}
                                           value={invitation.email}
                                           onValueChange={handleChangeValue}/>
                                <PhoneInput name="phoneNumber"
                                            label={t("invitation.phone_number", "Téléphone cellulaire")}
                                            country={defaultCountry ?? "CA" }
                                            value={invitation.phoneNumber ?? ""}
                                            onValueChange={handleChangeValue}/>

                            </Stack>
                        </FormGroup>
                        <TextInput rows={5}
                                   multiline
                                   name="message" label={t("invitation.message", "Message")}
                                   value={invitation.message}
                                   onValueChange={handleChangeValue}/>
                    </Stack>
                </CardContent>

                <CardActions sx={{justifyContent: 'flex-end'}}>
                    <CancelButton/>
                    <SubmitButton/>
                </CardActions>
            </Card>
        </Form>
    </FullScreenDialog>
}