import {PrayerEvent} from "@profusion.media/profusion-core-prayer"
import {Card, CardContent, CardHeader} from "@mui/material";
import {UserAvatar, TimeAgo} from "@profusion.media/profusion-ui";

export interface PrayerCommentListItemProps {
    comment: PrayerEvent
}

export const PrayerCommentListItem = (props: PrayerCommentListItemProps) => {
    const {comment} = props
    return <Card>
        <CardHeader
            avatar={<UserAvatar avatar={comment.createdBy?.avatar?.smallUrl} userName={comment.createdBy.displayName}/>}
            title={comment.createdBy.displayName}
            subheader={<TimeAgo date={comment.dateCreated} />}
        />
        <CardContent dangerouslySetInnerHTML={{__html: comment.comment ?? ""}} />
    </Card>

}
