import {faPrayingHands} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {DuoToneIcon, DuoToneIconProps} from "@profusion.media/profusion-ui";
import {faStars, faMessageSmile, faBookmark} from "@fortawesome/pro-duotone-svg-icons"



interface PrayingIconProps extends Omit<FontAwesomeIconProps | DuoToneIconProps, "icon"> {}

export const PrayingHandsIcon = (props: PrayingIconProps) => {
    return <FontAwesomeIcon icon={faPrayingHands} {...props} />
}

interface EmptyListIconProps extends PrayingIconProps {
    folder: "mine" | "shared" | "bookmarks"
}

export const EmptyListIcon = (props: EmptyListIconProps) => {
    const {folder, ...other} = props

    switch (folder) {
        case "mine":
            return <DuoToneIcon icon={faMessageSmile} {...other} />
        case "shared":
            return <DuoToneIcon icon={faStars} {...other} />
        case "bookmarks":
        default:
            return <DuoToneIcon icon={faBookmark} {...other} />
    }
}