import profusion from "../../../../config/profusion";
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {actionCreatePrayerComment as _create} from "@profusion.media/profusion-core-prayer";

export const actionCreatePrayerComment = async (props: ActionFunctionArgs) => {
    const prayerComment = await props.request.json()
    const response = await _create(profusion, prayerComment)
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect('..')
}
