import {Box, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import {PrayerRequest, PrayerRequestOverview} from "@profusion.media/profusion-core-prayer";
import {TimeAgo, UserAvatar} from "@profusion.media/profusion-ui";
import React, {useEffect, useState} from "react";
import {useNavigateRequestDetails} from "../hooks/navigation";
import {PrayerPrayedButton} from "../components/PrayerPrayedButton";
import {useRevalidator} from "react-router-dom";
import {usePrayerRequestOverview} from "../loaders";
import Typography from "@mui/material/Typography";
import {PrayerPinButton} from "../components/PrayerPinButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";

export interface PrayerRequestListItemProps {
    prayerRequest: PrayerRequest
    context?: "list" | "details" | "short" | "bookmark"
}

const defaultProps: Partial<PrayerRequestListItemProps> = {
    context: "list"
}

export const PrayerRequestListItem = (props: PrayerRequestListItemProps) => {
    const {prayerRequest, context} = {...defaultProps, ...props}
    const [overview, setOverview] = useState<PrayerRequestOverview>({
        prayerCount: 0,
        commentCount: 0,
        avatars: [],
        requestHid: prayerRequest.hid
    })
    const overviewData = usePrayerRequestOverview(prayerRequest)
    const navigate = useNavigateRequestDetails(prayerRequest.groupHid)
    const revalidator = useRevalidator()

    const isShort = context === "short"
    const isList = context === "list"
    const isBookmark = context === "bookmark"
    const isDetails = context === "details"
    const showDescription = isDetails

    useEffect(() => {
        overviewData.then((data: PrayerRequestOverview) => {
            setOverview(data)
        })
    }, [overviewData]);

    const onPrayed = () => {
        if (context === "details") {
            revalidator.revalidate()
        } else {
            revalidator.revalidate()
            setOverview(prev => ({...prev, ["prayerCount"]: prev.prayerCount + 1}))
        }
    }

    const onPinned = () => {
        revalidator.revalidate()
    }


    return <Card elevation={0}
                 sx={{borderStyle: "solid", borderWidth: 1, borderColor: "#ddd"}}
    >
        <CardHeader
            onClick={_ => navigate(prayerRequest.hid)}
            avatar={<UserAvatar avatar={prayerRequest.createdBy?.avatar?.smallUrl}
                                userName={prayerRequest.createdBy?.displayName}/>}
            title={prayerRequest.title}
            subheader={isBookmark ? <Typography variant="caption">
                Dans {prayerRequest.groupTitle},&nbsp;
                <TimeAgo container="span" date={prayerRequest.dateCreated}/>
            </Typography> : <TimeAgo container="span" date={prayerRequest.dateCreated}/>
            }
            sx={{backgroundColor: "#f0f0f0"}}
        />
        {showDescription && <CardContent
            dangerouslySetInnerHTML={{__html: prayerRequest.text}}/>}

        {!!prayerRequest.userPrayerCount && <CardContent sx={{backgroundColor: "#fafafa"}}>
            <Typography variant="caption" color="success">
                <FontAwesomeIcon icon={faCheckCircle}/>&nbsp;
                Vous avez prié {prayerRequest.userPrayerCount} fois pour cette requête,
                la dernière fois <TimeAgo date={prayerRequest.userLastPrayerDate ?? ""} container={"span"}/>
            </Typography>
        </CardContent>}

        {!isShort && <CardActions>
            <Box sx={{
                display: "flex", alignItems: "center",
                flex: "1 1",
                justifyContent: "space-between"
            }}>

                <PrayerPrayedButton
                    request={prayerRequest}
                    onComplete={onPrayed}
                    count={overview.prayerCount}
                />

                <PrayerPinButton
                    request={prayerRequest}
                    onComplete={onPinned}/>
            </Box>
        </CardActions>}

    </Card>
}