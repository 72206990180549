import {Group} from "@profusion.media/profusion-core-group"
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-light-svg-icons";
import {useNavigationInvitation} from "../../hooks/navigation";
import {useTranslation} from "react-i18next";
import {Await, useRouteLoaderData} from "react-router-dom";
import {LoadDetailsReturn} from "../loaders";

export interface GroupInvitationButtonProps {
    group?: Group
}


export const GroupInvitationButton = (props: GroupInvitationButtonProps) => {
    const navigateInvitation = useNavigationInvitation()
    const {t} = useTranslation()
    const {
        group: groupData
    } = useRouteLoaderData("groupDetails") as LoadDetailsReturn


    return <Await resolve={groupData}>
        {({data: group}) => <Button
            startIcon={<FontAwesomeIcon icon={faPlus}/>}
            variant="outlined"
            onClick={() => navigateInvitation(group.hid ?? "")}>{t("prayer.group.invite", "Inviter un ami")}</Button>}

    </Await>
}