import {Group} from "@profusion.media/profusion-core-group"
import {Alert, AlertTitle, Box, Button, Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-light-svg-icons";
import {useNavigationInvitationList} from "../../../hooks/navigation";
import {GroupInvitationButton} from "../GroupInvitationButton";

interface InvitationsInfoBoxProps {
    group: Group
}

export const InvitationsInfoBox = (props: InvitationsInfoBoxProps) => {
    const {group} = props
    const navigation = useNavigationInvitationList()

    if (!group.userRole?.isHost) {
        return <></>
    }

    if (!group.invitationsCount) {
        return <Box sx={{marginY: 4}}>
            <GroupInvitationButton/>
        </Box>
    }

    return <Alert color="info" sx={{marginY: 5}}>
        <AlertTitle>Invitations en attente</AlertTitle>
        <p>
            Vous avez actuellement {group.invitationsCount} invitations en cours
        </p>
        <Stack spacing={2} direction="row">
            <Button
                onClick={_ => navigation(group.hid)}
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faEye}/>}>Voir</Button>
            <GroupInvitationButton />
        </Stack>
    </Alert>
}