import React, {useEffect, useState} from 'react';
import './App.css';
import {AuthSession, useAuthentication, useProfusionSession} from "@profusion.media/profusion-core";
import DashboardRouter from "./screens/dashboard/DashboardRouter";
import {WaitingPage} from "@profusion.media/profusion-ui";
import profusion from "./config/profusion";

async function _sessionConnect(xr: string, session: AuthSession) {
    console.log("receiver : " + xr)
    await session.loginConnectToken(xr)
    console.log("receiver connected")
}

function App() {
    const {refresh} = useAuthentication()
    const searchParams = new URLSearchParams(window.location.search)
    const xr = searchParams.get("xr") ?? false
    const session = useProfusionSession()
    const [sessionReady, setSessionReady] = useState<boolean>(false)

    if (!profusion.session.is_authenticated) {
        window.location.href = "https://app.profusion.media/"
        return
    }

    const startSession = () => {
        setSessionReady(true)
    }

    useEffect(() => {
        if (xr) {
            _sessionConnect(xr, session).then(_ => {
                refresh().then((_: any) => {
                    startSession()
                })
            })
        } else {
            startSession()
        }
    }, [xr]);

    if (!sessionReady) {
        return <WaitingPage show={true}/>
    }

    return <DashboardRouter/>

}

export default App;
