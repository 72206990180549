import {Button, Container, Paper, Stack} from "@mui/material";
import {faUsersSlash} from "@fortawesome/pro-duotone-svg-icons"
import {DuoToneIcon} from "@profusion.media/profusion-ui";

export const HomeEmptyGroupsShared = () => {

    return (
        <Paper sx={{paddingX: 6, paddingY: 10}}>
            <Container maxWidth="sm">
                <Stack direction="column" spacing={4} alignItems="center">
                    <DuoToneIcon
                        size="5x"
                        style={{
                            "--fa-primary-color": "#0e5b85",
                            "--fa-secondary-color": "#0e5b85"
                        }}
                        icon={faUsersSlash}/>
                    <h2>Vous n'avez actuellement aucun groupe partagé avec vous</h2>
                </Stack>
            </Container>

        </Paper>
    )
}