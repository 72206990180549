import ListItemText from "@mui/material/ListItemText";
import {ListItem, ListItemAvatar, Skeleton} from "@mui/material";
import List from "@mui/material/List";

export interface GroupListSkeletonProps {
    count?: number
}

const GroupListItemSkeleton = () => {
    return <ListItem>
        <ListItemAvatar>
            <Skeleton animation="pulse"
                      variant="circular"
                      width={48}
                      height={48}
            />
        </ListItemAvatar>
        <ListItemText
            primary={<Skeleton animation="pulse" variant="text" />}
            secondary={<Skeleton animation="pulse" variant="text" width={200} />}
        />
    </ListItem>
}

export const GroupListSkeleton = (props: GroupListSkeletonProps) => {
    const {count} = props || {count: 3}
    return <List>
        {[...Array(count)].map((_, idx) => <GroupListItemSkeleton key={idx} />)}
    </List>
}