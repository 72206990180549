import {NotificationSettingsForm} from "@profusion.media/profusion-ui-notification";
import {Await, useLoaderData} from "react-router-dom";

import {LoadSubscriberSettingsReturn} from "../../loaders";
import {Suspense} from "react";
import {NotificationSettings} from "@profusion.media/profusion-core-notification";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";

export const GroupSubscriberSettings = () => {
    const {settings: settingsData} = useLoaderData() as LoadSubscriberSettingsReturn

    const change = (value: NotificationSettings) => {

    }

    return <List>
        <ListSubheader>Notifications</ListSubheader>
        <Suspense>
            <Await resolve={settingsData}>
                {(settings) => <NotificationSettingsForm value={settings} onChange={change}/>}
            </Await>
        </Suspense>
    </List>
}