import {Subscriber} from "@profusion.media/profusion-core-group";
import {UserAvatar} from "@profusion.media/profusion-ui";

export interface SubscriberAvatarProps {
    subscriber: Subscriber
}

export const SubscriberAvatar = (props: SubscriberAvatarProps) => {
    const {subscriber} = props
    return <UserAvatar userName={subscriber.user?.displayName}
                       avatar={subscriber.user?.avatar?.smallUrl} />
}