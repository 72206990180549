import profusion from "../../../../config/profusion";
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {
    actionCreatePrayerRequest as _create,
    actionUpdatePrayerRequest as _update, PrayerRequestUpdateInput, PrayerRequest
} from "@profusion.media/profusion-core-prayer";

export const actionCreatePrayerRequest = async (props: ActionFunctionArgs) => {
    const prayerRequest = await props.request.json()
    const response = await _create(profusion, prayerRequest)
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect(`/dashboard/groups/${response.data?.prayerRequest.groupHid}/prayer`)
}

export const actionUpdatePrayerRequest = async (props: ActionFunctionArgs) => {
    const prayerRequest = await props.request.json()
    const response = await _update(profusion, prayerRequest)
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect(`/dashboard/groups/${response.data?.prayerRequest.groupHid}/prayer/${response.data?.prayerRequest.hid}`)
}

export const useUpdatePrayerRequest = () : (request: PrayerRequestUpdateInput) => Promise<any> => {
    return (request: PrayerRequestUpdateInput) => _update(profusion, request)
}