import {defer, LoaderFunctionArgs} from "react-router-dom"
import profusion from "../../../../config/profusion";
import {
    loadPrayerRequestComments as _loaderComments,
    loadPrayerRequestDetails as _loaderRequest,
    loadPrayerRequestOverview as _loaderOverview,
} from "@profusion.media/profusion-core-prayer"

export type RequestDetailsLoaderProps = LoaderFunctionArgs<["hid", "rhid"]>


export type LoadPrayerRequestDetailsReturn = {
    prayerRequest: ReturnType<typeof _loaderRequest>
    comments: ReturnType<typeof _loaderComments>
    overview: ReturnType<typeof  _loaderOverview>
    groupHid: string
    requestHid: string
}

export const loadPrayerRequestDetails = (loaderProps: RequestDetailsLoaderProps) => {
    const requestHid = loaderProps.params.rhid ?? ""
    const groupHid = loaderProps.params.hid ?? ""
    return defer({
        prayerRequest: _loaderRequest(profusion, requestHid),
        comments: _loaderComments(profusion, requestHid),
        overview: _loaderOverview(profusion, requestHid),
        groupHid,
        requestHid
    })
}
