import React, {Suspense, useEffect, useState} from "react";
import {Await, Outlet, useLoaderData, useNavigate, useParams} from "react-router-dom";
import {PrayerRequestListItem} from "../../PrayerRequestList/PrayerRequestListItem";
import {IconButton, Stack} from "@mui/material";
import {LoadPrayerRequestDetailsReturn} from "../../loaders";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {PrayerCommentAddButton} from "../comments/PrayerCommentAddButton";
import {PrayerCommentList} from "../comments/PrayerCommentList";
import {useNavigateRequestEdit} from "../../hooks/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/pro-light-svg-icons";


export const PrayerRequestDetails = () => {
    const {hid, rhid} = useParams()
    const {prayerRequest: requestData, comments: commentsData, overview: overviewData} = useLoaderData() as LoadPrayerRequestDetailsReturn
    const navigate = useNavigate()
    const [dialogTitle, setDialogTitle] = useState<string>("Loading...")
    const navigateEdit = useNavigateRequestEdit(hid ?? "")


    const handleCancel = () => {
        navigate('..')
    }

    useEffect(() => {
        requestData.then(r => {
            setDialogTitle(r.data.title)
        })
    }, [requestData]);

    return <>
        <FullScreenDialog title={<Stack direction="row" useFlexGap><span>{dialogTitle}</span> <IconButton
            onClick={_ => navigateEdit(rhid ?? "")}><FontAwesomeIcon icon={faEllipsisVertical}/></IconButton>
        </Stack>}
                          maxWidth="lg"
                          onClose={handleCancel}>
            <Suspense>
                <Await resolve={requestData}>
                    {({data: prayerRequest}) => <PrayerRequestListItem
                        context="details"
                        prayerRequest={prayerRequest}/>}
                </Await>


            </Suspense>
            <Stack spacing={4} sx={{marginTop: 4}}>
                <PrayerCommentAddButton
                    url={`/dashboard/groups/${hid}/prayer/${rhid}/comment/add`}/>
                <Suspense>
                    <Await resolve={commentsData}>
                        {({data: comments}) => <PrayerCommentList comments={comments}/>}
                    </Await>
                </Suspense>
            </Stack>
        </FullScreenDialog>
        <Outlet/>
    </>
}