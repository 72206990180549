import {PrayerRequest} from "@profusion.media/profusion-core-prayer"
import {Box, Button, Card, CardActions, CardHeader, Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark} from "@fortawesome/pro-light-svg-icons";
import List from "@mui/material/List";
import {PrayerRequestListItem} from "../../Prayers/PrayerRequestList/PrayerRequestListItem";

export interface HomeScreenFavoritesProps {
    requests: PrayerRequest[]
    onMore: () => void
}

export const HomeScreenFavorites = (props: HomeScreenFavoritesProps) => {
    const {requests, onMore} = props

    return <Card>
        <CardHeader title={<Box><FontAwesomeIcon icon={faBookmark}/> Mes favoris</Box>}/>
        <List>{requests
            .slice(0, 3)
            .map((r: PrayerRequest) => <PrayerRequestListItem prayerRequest={r}
                                                              context="short"
                                                              key={r.hid}/>)}</List>
        <CardActions>
            <Stack direction="row-reverse">
                <Button onClick={onMore}>Voir plus</Button>
            </Stack>
        </CardActions>
    </Card>
}