import profusion from "../../../../config/profusion";
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {actionAddPrayedEvent as _create, PrayerRequest} from "@profusion.media/profusion-core-prayer";

export const actionAddPrayerEvent = async (props: ActionFunctionArgs) => {
    const prayerComment = await props.request.json()
    const response = await _create(profusion, {
        requestHid: prayerComment.requestHid
    })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return response.data?.prayerEvent
}

export const useAddPrayerEvent = (request: PrayerRequest) : () => Promise<any> => {
    return () => _create(profusion, {requestHid: request.hid})
}