import {Await, Outlet, useLoaderData} from "react-router-dom";
import {LoadPinnedRequestListReturn} from "../loaders";
import {Suspense} from "react";
import {Avatar, Card, CardHeader, Container, Stack} from "@mui/material";
import {PrayerRequest} from "@profusion.media/profusion-core-prayer";
import {PrayerRequestListItem} from "../PrayerRequestList/PrayerRequestListItem";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark} from "@fortawesome/sharp-solid-svg-icons"
import {PinnedRequestListEmpty} from "./PinnedRequestListEmpty";

export const PinnedRequestList = () => {
    const {t} = useTranslation()
    const {prayerRequests: requestsData} = useLoaderData() as LoadPinnedRequestListReturn

    return <>
        <Card sx={{boxShadow: 1}}>
            <CardHeader title={t("prayer.bookmarks.title", "Mes signets")}
                        avatar={<Stack direction="row" spacing={1} alignItems="center">
                            <Avatar>
                                <FontAwesomeIcon icon={faBookmark}/>
                            </Avatar>
                        </Stack>}
                        titleTypographyProps={{variant: "h5"}}
            />
        </Card>
        <Container>
            <Suspense>
                <Stack spacing={4} sx={{marginTop: 4}}>
                    <Await resolve={requestsData}>
                        {({data: prayerRequests}) => prayerRequests.length ? prayerRequests.map((r: PrayerRequest) => <PrayerRequestListItem
                            key={r.hid}
                            context="bookmark"
                            prayerRequest={r}/>)
                        : <PinnedRequestListEmpty />}
                    </Await>
                </Stack>
            </Suspense>
            <Outlet/>
        </Container>

    </>
}
