import {Box} from "@mui/material";
import {faCirclePlus} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export interface PrayerRequestAddButtonProps {
    url: string
}

export const PrayerRequestAddButton = (props: PrayerRequestAddButtonProps) => {
    const {url} = props
    const navigate = useNavigate()

    const {t} = useTranslation()
    return <Box
        marginTop={4}
        alignItems="center"
        display="flex"
        flexDirection="row"
        onClick={_ => navigate(url)}>
        <FontAwesomeIcon
            color="#888"
            icon={faCirclePlus}
            size="2x"/>
        <Box flex={1}
            padding={2}
            borderRadius={1}
            marginLeft={2}
             sx={{backgroundColor: "#fff",
             borderColor: "#ddd",
             borderStyle: "solid",
             borderWidth: 1}}
        >
        <Typography fontStyle="italic">{t("prayer.request.button.add", "Ajouter une requête")}</Typography>
        </Box>
    </Box>
}