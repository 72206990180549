import {Grid, Paper} from "@mui/material";
import {EmptyListIcon} from "../components/icons/PrayerIcons";
import Typography from "@mui/material/Typography";

export const PinnedRequestListEmpty = () => {

    return <Paper sx={{p: 4}}>
        <Grid container maxWidth="sm">
            <Grid item xs={12} sm={3} textAlign="center">
                <EmptyListIcon folder="bookmarks"
                               size="5x"
                               style={{
                                   "--fa-primary-color": "#62a9d7",
                                   "--fa-secondary-color": "#53b2ec",
                                   "--fa-secondary-opacity": 1

                               }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Vous n'avez pas encore de signets</Typography>
                <Typography variant="body2">Ajoutez des signets sur les requêtes de prière pour y accéder plus
                    rapidement</Typography>
            </Grid>
        </Grid>
    </Paper>
}
