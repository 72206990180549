import {Box, Button, Container, Paper, Stack, Typography} from "@mui/material";
import {DuoToneIcon} from "@profusion.media/profusion-ui";
import {faFaceAwesome} from "@fortawesome/pro-duotone-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const GroupCreateConfirm = () => {
    const {hid} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    return <Container maxWidth="sm">
        <Paper sx={{p:6, marginTop: 4}}>
        <Stack direction="column"
               alignItems="center"
               justifyContent="center"
               spacing={4}>
            <Box>
                <DuoToneIcon icon={faFaceAwesome}
                             size="6x"
                             style={{
                                 "--fa-primary-color": "#9c27b0",
                                 "--fa-primary-opacity": "0.8",
                                 "--fa-secondary-color": "#1976d2",
                                 "--fa-secondary-opacity": "0.5"
                             }}/>
            </Box>

            <Typography variant="h4">Bravo !</Typography>
            <Typography variant="h5">Vous venez de créer un nouveau groupe, invitez dès maintenant vos partenaires de
                prières.</Typography>
            <Button variant="contained"
                    onClick={_ => _}
            >{t("prayer.group.create.send-invitation", "Envoyer des invitations")}</Button>
            <Button color="inherit"
                    onClick={_ => navigate(`/dashboard/groups/${hid}`)}
            >{t("common.later", "Plus tard")}</Button>

        </Stack>
        </Paper>
    </Container>
}