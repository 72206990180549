import {Button} from "@mui/material";
import {useAuthentication} from "@profusion.media/profusion-core"
import {useLoginNavigation} from "../../../../../utils/login_navigation";
import {actionInvitationAccept, Group} from "@profusion.media/profusion-core-group"
import {Invitation} from "@profusion.media/profusion-iam-user"
import {useNavigate} from "react-router-dom";
import {actionAddPrayedEvent as _create, PrayerRequest} from "../../../../../../../../npm/profusion-core-prayer";
import profusion from "../../../../../config/profusion";
import {useState} from "react";
import {LoadingIcon} from "../../../../../components/icons/LoadingIcon";

type InvitationAcceptButtonProps = {
    group: Group
    invitation: Invitation
}


export const useInvitationAccept = (groupHid: string, invitationHid: string) : () => Promise<any> => {
    return () => {
        console.log("Use Accept", groupHid, invitationHid, profusion.session)
        return actionInvitationAccept(profusion, groupHid, invitationHid)
    }
}

export const InvitationAcceptButton = (props: InvitationAcceptButtonProps) => {
    const {group, invitation} = props
    const navigate = useNavigate()
    const accept = useInvitationAccept(group.hid, invitation.hid)
    const [loading, setLoading] = useState<boolean>(false)

    const handleClick = () => {
        setLoading(true)
        accept().then(() => {
            setLoading(false)
            navigate("accept")
        })
    }

    return <Button
        startIcon={loading ? <LoadingIcon/> : undefined}
        onClick={handleClick}
        variant="contained"
    >Oui, je veux rejoindre le groupe !</Button>
}