import {Await, useLoaderData, useParams, useRevalidator} from "react-router-dom";
import {useAcceptInvitationMutation} from "@profusion.media/profusion-core-group";
import React, {useMemo} from "react";
import {FullScreenDialog, HeadsUp} from "@profusion.media/profusion-ui";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardContent, Container, Stack, styled} from "@mui/material";
import {faArrowRight, faLightbulb} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import {LoadGroupReturn} from "../GroupDetails/loaders";
import {useNavigateDetails} from "../hooks/navigation";

const ContainerWithGradient = styled(Card)(({theme}) => ({
    background: `linear-gradient(174deg, #fafafe 100px, transparent 16px, transparent)`,
    padding: theme.spacing(2),
}));

export const GroupInvitationAccept = () => {
    const {hid, ihid} = useParams()
    const {t} = useTranslation()
    const {group: groupData} = useLoaderData() as LoadGroupReturn
    const navigate = useNavigateDetails()


    const handleClose = () => {
        navigate(hid ?? "")
    }

    return <FullScreenDialog
        title={t("invitation.accept.prayer.title", "Rejoindre un groupe de prière")}
        onClose={handleClose}
    >
        <Await resolve={groupData}>
            {({data: group}) =>
                <Container maxWidth="md">
                    <Stack spacing={4} direction="column" >
                        <ContainerWithGradient>
                            <CardContent>
                                <Stack direction="column" spacing={4}>
                                <Typography variant="h2">
                                    {t("invitation.accept.prayer.title1", "Félicitations !")}
                                </Typography>
                                <Typography variant="h4">
                                    {t("invitation.accept.prayer.title2", {
                                        defaultValue: "Vous avez rejoint le groupe de prière de {{name}}",
                                        name: group.createdBy.displayName
                                    })}
                                </Typography>

                                    <Box>

                                        <Button
                                            endIcon={
                                                <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                />
                                            }
                                            color="primary"
                                            variant="contained"
                                            onClick={handleClose}
                                        >{t("invitation.accept.prayer.next", "Continuer")}</Button>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </ContainerWithGradient>

                        <HeadsUp>
                            <FontAwesomeIcon
                                size="2x"
                                icon={faLightbulb}
                                style={{marginRight: '8px'}}
                            />
                            <Typography variant="body1">
                                Saviez-vous que vous aussi vous pouvez créer votre groupe de prière sur la plateforme
                                Profusion.media ?
                                <a href="#">En savoir plus</a>
                            </Typography>
                        </HeadsUp>
                    </Stack>
                </Container>
            }
        </Await>
    </FullScreenDialog>
}