import {Group} from "@profusion.media/profusion-core-group";
import {UserAvatar} from "@profusion.media/profusion-ui";

export interface GroupAvatarProps {
    group: Group
}

export const GroupAvatar = (props: GroupAvatarProps) => {
    const {group} = props

    return <UserAvatar
        avatar={group.avatar?.smallUrl}
        userName={group.title}/>
}