import {useTranslation} from "react-i18next"
import {Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

export const GroupSettingsHelp = () => {
    const {t} = useTranslation()

    return <Stack spacing={4} sx={{p: 4, maxWidth: "sm"}} >
        <Box>
            <Typography fontWeight="bold"
                variant="body1">{t("prayer.groups.create.help.title.headline", "Comment choisir un titre ?")}</Typography>
            <Typography
                variant="body2">{t("prayer.groups.create.help.title.body", "Le titre de votre groupe est autant utile pour vous repérer dans vos listes de groupes que pour les participants que vous invitez. Précisez en quelques mots le but du groupe, par exemple \"Soutien pour la mission d'évangélisation\"")}</Typography>
        </Box>
        <Box>
            <Typography fontWeight="bold"
                variant="body1">{t("prayer.groups.create.help.description.headline", "Comment rédiger une description ?")}</Typography>
            <Typography
                variant="body2">{t("prayer.groups.create.help.description.body", "La description permet de créer le premier contact avec vos participants invités. Informez-les de votre objectif, de ce que vous souhaitez qu'ils prient et des modalités de votre groupe.")}</Typography>

        </Box>
        <Box>
            <Typography fontWeight="bold"
                variant="body1">{t("prayer.groups.create.help.language.headline", "Langue du groupe")}</Typography>
            <Typography
                variant="body2">{t("prayer.groups.create.help.language.body", "Il s'agit de la langue dans laquelle vous souhaitez communiquer avec les autres participants du groupe.")}</Typography>

        </Box>
    </Stack>
}