import {defer, LoaderFunctionArgs} from "react-router-dom";
import {
    loadGroupActiveSubscribers,
    loadGroupDetails,
    loadAllPrayerGroupOverview,
    loadGroupPendingInvitations,
    loaderGroupSubscriberSettings
} from "@profusion.media/profusion-core-group";
import profusion from "../../../../config/profusion";
import {loadNotificationSettings} from "@profusion.media/profusion-core-notification";


export type DetailsLoaderProps = LoaderFunctionArgs<["hid"]>

export type  LoadGroupReturn = {
    group: ReturnType<typeof loadGroupDetails>,
}

export type  LoadDetailsReturn = {
    group: ReturnType<typeof loadGroupDetails>,
    subscribers: ReturnType<typeof loadGroupActiveSubscribers>,
    invitations: ReturnType<typeof loadGroupPendingInvitations>
}

export type LoadAllPrayerGroupOverviewReturn = {
    overviews: ReturnType<typeof loadAllPrayerGroupOverview>
}

export const loadGroup = (loaderProps: DetailsLoaderProps) => {
    const hid = loaderProps.params.hid ?? ""
    return defer({
        group: loadGroupDetails(profusion, hid),
    })
}

export const loadDetails = (loaderProps: DetailsLoaderProps) => {
    const hid = loaderProps.params.hid ?? ""
    return defer({
        group: loadGroupDetails(profusion, hid),
        subscribers: loadGroupActiveSubscribers(profusion, hid),
        invitations: loadGroupPendingInvitations(profusion, hid)
    })
}

export const loadAllOverviews = (loaderProps: LoaderFunctionArgs) => {
    return defer({
        overviews: loadAllPrayerGroupOverview(profusion)
    })
}

export type SubscriberSettingsLoaderProps = LoaderFunctionArgs<["hid"]>
export type  LoadSubscriberSettingsReturn = {
    settings: ReturnType<typeof loadNotificationSettings>
}

export const loadSubscriberSettings = (loaderProps: SubscriberSettingsLoaderProps) => {
    const hid = loaderProps.params.hid ?? ""
    return defer({
        settings: loaderGroupSubscriberSettings(profusion, hid),
    })
}