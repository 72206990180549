import {Box, Container, createTheme, CssBaseline, Stack, ThemeProvider, Toolbar} from "@mui/material";
import React from "react";
import {
    AccountDropDownMenu,
    AppBar,
    DrawerMenu,
    DrawerMenuProvider,
} from "@profusion.media/profusion-ui";
import {DrawerItems} from "../../../screens/dashboard/components/drawer/DrawerItems";
import {NotificationIcon} from "@profusion.media/profusion-ui-notification";
import {useTranslation} from "react-i18next";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";


const mdTheme = createTheme();

interface DashboardLayoutProps {
    children: React.ReactNode | React.ReactNode[]

}

const DashboardLayout = (props: DashboardLayoutProps) => {
    const {t} = useTranslation()
    const user = useConnectedUser()

    if (!user) return <></>

    return <ThemeProvider theme={mdTheme}>
        <DrawerMenuProvider>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar title={t("prayer.app.title", "Prière") ?? ""}>
                    <Stack direction="row" spacing={4}>
                        <AccountDropDownMenu user={user}/>
                    </Stack>
                </AppBar>

                <DrawerMenu>
                    <DrawerItems/>
                </DrawerMenu>

                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[50]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{ mb: 4}} disableGutters>
                        {props.children}
                    </Container>
                </Box>
            </Box>
        </DrawerMenuProvider>
    </ThemeProvider>
}

export default DashboardLayout