import DashboardLayout from "../../components/layout/dashboard/DashboardLayout";

import {Outlet} from "react-router-dom";
import {ConnectedUserProvider} from "@profusion.media/profusion-iam-user";
import {useAuthentication} from "@profusion.media/profusion-core";

export const DashboardApp = () => {
    const {is_authenticated} = useAuthentication()



    if (is_authenticated) {
        return <ConnectedUserProvider>
            <DashboardLayout>
                <Outlet/>
            </DashboardLayout>
        </ConnectedUserProvider>
    } else {
        window.location.href = "https://app.profusion.media/logout"
    }
}