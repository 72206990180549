import {useAppReady} from "@profusion.media/profusion-core";
import {ReactElement} from "react";

export interface WaitingScreenProps {
    children: ReactElement
}

export const WaitingScreen = (props: WaitingScreenProps) => {
    const {children} = props
    const appReady = useAppReady();
    if (!appReady) {
        return <div>Loading...</div>;
    }
    return children
}