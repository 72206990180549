import {getPrayerGroupOverview, Group, PrayerGroupOverview} from "@profusion.media/profusion-core-group";
import ListItemText from "@mui/material/ListItemText";
import {Card, CardContent, CardHeader, ListItem} from "@mui/material";
import {useNavigateDetails} from "../../hooks/navigation";
import {Await, useRouteLoaderData} from "react-router-dom";
import {LoadAllPrayerGroupOverviewReturn} from "../../GroupDetails/loaders";
import React from "react";
import {GroupAvatar} from "../../GroupDetails/components/GroupAvatar";
import {useTranslation} from "react-i18next";

export interface GroupListItemProps {
    group: Group
}

export interface GroupListItemOverviewProps {
    group: Group
    overview: PrayerGroupOverview
}

const GroupListItemOverview = (props: GroupListItemOverviewProps) => {
    const {group, overview} = props
    const {t} = useTranslation()

    if (!overview) return <></>

    return <ListItem>
        <ListItemText primary={overview.subscriberCount} secondary={t("prayer.overview.partners_count", {
            defaultValue: "partenaires",
            count: overview.subscriberCount
        })}/>
        <ListItemText primary={overview.requestCount} secondary={t("prayer.overview.requests_count", {
            defaultValue: "requêtes",
            count: overview.requestCount
        })}/>
        <ListItemText primary={overview.prayerCount} secondary={t("prayer.overview.prayers_count", {
            defaultValue: "prières",
            count: overview.prayerCount
        })}/>
    </ListItem>
}

export const GroupListItem = (props: GroupListItemProps) => {
    const navigateDetails = useNavigateDetails()
    const {group} = props
    const {overviews} = useRouteLoaderData("groupsRoot") as LoadAllPrayerGroupOverviewReturn


    return <Card elevation={0}
                 onClick={_ => navigateDetails(group.hid)}
                 sx={{borderStyle: "solid", borderWidth: 1, borderColor: "#ddd"}}
    >
        <CardHeader
            avatar={<GroupAvatar group={group}/>}
            title={group.title}
            subheader={group.createdBy?.displayName}
            sx={{backgroundColor: "#f0f0f0"}}
        />
        <CardContent>
            <Await resolve={overviews}>
                {({data}) => <GroupListItemOverview group={group}
                                                    overview={getPrayerGroupOverview(group, data)}/>}

            </Await>
        </CardContent>
    </Card>
}