import {getPrayerGroupOverview, Group, PrayerGroupOverview} from "@profusion.media/profusion-core-group"
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {Await, useRouteLoaderData} from "react-router-dom";
import {LoadAllPrayerGroupOverviewReturn} from "../loaders";
import React from "react";

export interface GroupDetailsSubHeaderProps {
    group: Group
}

interface GroupDetailsStatsLineProps {
    group: Group
    data: PrayerGroupOverview[]
}

const GroupDetailsStatsLine = (props: GroupDetailsStatsLineProps) => {
    const {t} = useTranslation()
    const {group, data} = props
    const groupData = getPrayerGroupOverview(group, data)

    if (!groupData) return <></>

    return <Stack direction="row" spacing={1} divider={<Typography variant="subtitle1">&bull;</Typography>}>
        <Typography variant="subtitle1">{t("prayer.group.header.stats", {
            defaultValue: "{{ subscribers }} partenaires",
            count: groupData?.subscriberCount ?? 0,
            subscribers: groupData?.subscriberCount ?? 0
        })}</Typography>
        <Typography variant="subtitle1">{t("prayer.group.header.stats.requests", {
            defaultValue: "{{ requests }} requêtes",
            count: groupData?.requestCount ?? 0,
            requests: groupData?.requestCount ?? 0
        })}</Typography>
        <Typography variant="subtitle1">{t("prayer.group.header.stats.prayers", {
            defaultValue: "{{ prayers }} prières",
            count: groupData?.prayerCount ?? 0,
            prayers: groupData?.prayerCount ?? 0
        })}</Typography>


    </Stack>
}

export const GroupDetailsSubHeader = (props: GroupDetailsSubHeaderProps) => {
    const {group} = props
    const {overviews} = useRouteLoaderData("groupsRoot") as LoadAllPrayerGroupOverviewReturn

    return <Await resolve={overviews}>
        {({data}) => <GroupDetailsStatsLine group={group} data={data}/>}
    </Await>
}