import {Await, Outlet, useParams, useRouteLoaderData} from "react-router-dom";
import {PendingInvitation} from "@profusion.media/profusion-core-group";
import List from "@mui/material/List";
import {Avatar, Card, Chip, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import {LoadDetailsReturn} from "../../loaders";
import {Suspense} from "react";
import {useNavigateDetailsTab} from "../../../hooks/navigation";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {GroupInvitationButton} from "../GroupInvitationButton";

export const GroupInvitationList = () => {
    const {
        invitations: invitationsData
    } = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const {hid: group} = useParams()

    const navigationBack = useNavigateDetailsTab(group ?? "")

    const handleCancel = () => {
        navigationBack("subscribers")
    }

    return <FullScreenDialog title="Invitations en attente" onClose={handleCancel}>
        <Suspense fallback={<Typography>Loading invitations...</Typography>}>
            <GroupInvitationButton />
            <Card sx={{marginTop: 2}}>
                <Await resolve={invitationsData}>
                    {({data: invitations}) => <List>
                        {invitations && invitations.map((subscriber: PendingInvitation) => <ListItem
                            key={subscriber.hid}>
                            <ListItemIcon>
                                <Avatar/>
                            </ListItemIcon>
                            <ListItemText primary={subscriber.invitation.recipientEmail ?? ""}
                                          secondary={<Chip label={subscriber.isHost
                                              ? "Animateur" : ""}/>}
                            />
                        </ListItem>)}
                    </List>}
                </Await>
            </Card>

            <Outlet/>
        </Suspense>
    </FullScreenDialog>

}