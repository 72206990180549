import React from 'react';
import "./config/i18n"
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ProfusionProvider} from "@profusion.media/profusion-core";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import profusion from "./config/profusion"
import {WaitingScreen} from "./components/WaitingScreen";

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};

// Initialisation du thème avec les breakpoints personnalisés
const theme = createTheme({
    breakpoints
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
        <ProfusionProvider client={profusion}>
            <WaitingScreen>
                <App/>
            </WaitingScreen>
        </ProfusionProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
