import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { DateTime } from 'luxon';

const locizeOptions = {
    projectId: 'b968b204-6dec-43e9-b69f-07c5dd6ca1bd',
    apiKey: '47296feb-4374-4b92-9b0f-d389618a229e',
    referenceLng: 'fr-FR',
    version: 'latest',
    allowedAddOrUpdateHosts: ['localhost', 'profusion.media', '127.0.0.1'],
};

i18n
    // i18next-locize-backend
    // loads translations from your project, saves new keys to it (saveMissing: true)
    // https://github.com/locize/i18next-locize-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'fr-FR',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // format: (value, format, lng) => { // legacy usage
            //   if (value instanceof Date) {
            //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
            //   }
            //   return value;
            // }
        },
        backend: locizeOptions,
        saveMissing: true,
        saveMissingTo: "all"
    });

// new usage
if (i18n.services) {
    i18n.services.formatter?.add('DATE_HUGE', (value, lng, options) => {
        return DateTime.fromJSDate(value).setLocale(lng ?? 'fr').toLocaleString(DateTime.DATE_HUGE)
    });
}

export default i18n;