import React from "react"
import {useParams} from "react-router-dom";
import {useGroupDetails, useInvitationDetails} from "@profusion.media/profusion-core-group";
import {Box, Card, CardActions, CardContent, CardHeader, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getLanguageDef, Language, useAuthentication} from "@profusion.media/profusion-core";
import {useTranslation} from "react-i18next";
import {SettingsListItem} from "@profusion.media/profusion-ui";
import {InvitationAcceptButton} from "./components/InvitationAcceptButton";

export const GroupInvitationDetails = () => {
    const {hid, ihid} = useParams()
    const {data: invitation, loading} = useInvitationDetails(ihid ?? "")
    const {data: group, loading: loadingGroup} = useGroupDetails(hid ?? "")
    const {t} = useTranslation()

    if (loading || loadingGroup || !group || !invitation) {
        return <></>
    }

    return <Box sx={{backgroundColor: "#fafafa", height: '100vh'}}>
        <Container fixed sx={{marginY: 4}}>
            <Box>
                <Typography variant="h3">
                    {t("invitation.greeting.headline", "Vous avez été invité")}
                </Typography>
                <Typography variant="h4">
                    {t("invitation.greeting.subtitle", "à rejoindre le groupe de prière de")}

                </Typography>
                <Typography variant="h4">
                    {invitation.invitedBy.displayName}
                </Typography>

                <Card sx={{mt: 6}}>
                    <CardHeader
                        title={t("invitation.details.title", "À propos de ce groupe")}
                    />
                    <CardContent>
                        <SettingsListItem label={t("invitation.details.group.title", "Nom du groupe")}
                                          value={group.title}/>
                        <SettingsListItem label={t("invitation.details.group.language", "Langue du groupe")}
                                          value={getLanguageDef(group.language as Language).label}/>
                    </CardContent>
                </Card>
                <Card sx={{mt: 4}}>
                    <CardHeader
                        title={t("invitation.details.title2", "À propos de cette invitation")}
                    />
                    <CardContent>
                        <SettingsListItem label={t("invitation.details.sender.name", "Invitation envoyée par")}
                                          value={invitation.invitedBy.displayName}/>
                        <SettingsListItem label={t("invitation.details.sender.message", "Message")}
                                          value={invitation.message ?? t("invitation.details.sender.nomessage", "Aucun message")}/>
                    </CardContent>

                    <CardActions>
                        <InvitationAcceptButton group={group} invitation={invitation} />
                    </CardActions>
                </Card>
            </Box>
        </Container>
    </Box>
}