import {Await, Outlet, useLoaderData, useParams} from "react-router-dom";
import {Card, CardHeader, Container, Stack} from "@mui/material";
import {LoadDetailsReturn} from "./loaders";
import {Suspense, useEffect} from "react";
import {GroupTabs} from "./components/GroupTabs";
import {GroupAvatar} from "./components/GroupAvatar";
import {useTranslation} from "react-i18next";
import {useNavigateDetailsTab} from "../hooks/navigation";
import {useTabIndex} from "./hooks";
import {GroupBackButton} from "./components/GroupBackButton";
import {GroupHeaderActions} from "./components/GroupHeaderActions";
import {Group} from "@profusion.media/profusion-core-group"
import {GroupDetailsSubHeader} from "./components/GroupDetailsSubHeader";

export const GroupDetails = () => {
    const {group: groupData} = useLoaderData() as LoadDetailsReturn
    const {t} = useTranslation()
    const {hid} = useParams()
    const navigateTab = useNavigateDetailsTab(hid || "")
    const tabIndex = useTabIndex()

    useEffect(() => {
        if (!tabIndex) {
            navigateTab("prayer")
        }
    }, []);

    return <Suspense>
        <Await resolve={groupData}>
            {({data: group}: { data: Group }) => <>
                <Card sx={{boxShadow: 1}}>
                    <CardHeader title={group.title}
                                avatar={<Stack direction="row" spacing={1} alignItems="center">
                                    <GroupBackButton group={group}/>
                                    <GroupAvatar group={group}/>
                                </Stack>}
                                titleTypographyProps={{variant: "h5"}}
                                subheader={<GroupDetailsSubHeader group={group} />}
                                action={<GroupHeaderActions group={group}/>}
                    />
                    <GroupTabs group={group}/>
                </Card>
                <Container>
                <Outlet/>
                </Container>
            </>
            }
        </Await>
    </Suspense>
}