import {useLocation} from "react-router-dom";
import {useProfusionSession} from "@profusion.media/profusion-core";

export const Receiver = () => {
    const loc = useLocation()
    const searchParams = new URLSearchParams(loc.search)
    const xr = searchParams.get("xr") ?? false
    const session = useProfusionSession()

    if (!xr) {
        window.location.href = "/"
    } else {
        session.loginConnectToken(xr).then((_: any) => {
            window.location.href = "/"
        })
    }

    return <></>
}